import React, { useEffect, useState } from "react";
import {
  APPS,
  BottomWrapperStages,
  GALLERY,
  Header,
  LANDING,
  LINKS,
  PDF,
  Social,
  URL,
  VCARD,
  Video,
  WIFI,
  YOUTUBE,
  BUSINESS,
  EVENT,
} from "../../components";
import { useNavigate, useParams } from "react-router-dom";
import { UrlSchema, youtubeSchema } from "../../Helper/QRValidation";
import { toast } from "react-toastify";
import { useDispatch, useSelector } from "react-redux";
import { resetQrData, setsQrData } from "../../redux/slice/qrSlice";

const QRDetail = () => {
  const { user } = useSelector((store) => store.user);
  const dispatch = useDispatch();
  const { type } = useParams();
  console.log("typeeee", type);
  // const initialState = {
  //   qr_name: "",
  //   id: "",
  //   //URL
  //   field_url: "",
  //   //WIFI
  //   network_name: user?.user?.email || user?.email,
  //   network_password: user?.user?.password || user?.password,
  //   network_security_type: "",
  //   //YOTUBE
  //   youtube_url: "",
  //   //PDF
  //   pdf_file: "",
  //   pdf_company: "",
  //   pdf_title: "",
  //   pdf_description: "",
  //   pdf_website: "",
  //   //APPS
  //   app_name: "",
  //   app_company: "",
  //   app_description: "",
  //   app_logo: "",
  //   app_website: "",
  //   app_social: {},
  //   //LANDING
  //   landing_action_url: "",
  //   landing_company: "",
  //   landing_logo: "",
  //   landing_title: "",
  //   landing_subtitle: "",
  //   landing_social: {},
  //   landing_btn_text: "",
  //   //SOCIAL
  //   media_social: {},
  //   media_headline: "",
  //   media_description: "",

  //   //image_gallery
  //   gallery_image: "",
  //   gallery_title: "",
  //   gallery_description: "",
  //   gallery_website: "",
  //   gallery_url: "",
  //   gallery_btn_text: "",

  //   //LINKS
  //   links_image: "",
  //   links_title: "",
  //   links_description: "",
  //   all_links: [],
  //   links_social: {},

  //   //VCARD
  //   vcard_address: "",
  //   vcard_city: "",
  //   vcard_company_name: "",
  //   vcard_country: "",
  //   vcard_email: "",
  //   vcard_fax: "",
  //   vcard_full_name: "",
  //   vcard_landline_phone: "",
  //   vcard_mobile_phone: "",
  //   vcard_numeration: "",
  //   vcard_profession: "",
  //   vcard_state: "",
  //   vcard_summary: "",
  //   vcard_website: "",
  //   vcard_zip_code: "",
  //   vcard_social: "",
  //   vcard_image: "",

  //   //BUSINESS
  //   business_email: "", //
  //   business_name: "", //
  //   business_phone: "", //
  //   business_website: "", //
  //   business_action_title: "",
  //   business_url: "", //
  //   business_company: "", //
  //   business_subtitle: "", //
  //   business_title: "", //
  //   business_btn_text: "", //
  //   business_address: "", //
  //   business_city: "", //
  //   business_country: "", //
  //   business_numeration: "", //
  //   business_postalcode: "", //
  //   business_state: "", //
  //   business_about: "", //

  //   opening_hours_days: {
  //     monday: { enabled: false, times: [] },
  //     tuesday: { enabled: false, times: [] },
  //     wednesday: { enabled: false, times: [] },
  //     thursday: { enabled: false, times: [] },
  //     friday: { enabled: false, times: [] },
  //     saturday: { enabled: false, times: [] },
  //     sunday: { enabled: false, times: [] },
  //   },
  //   opening_hours_format: "AM-PM",

  //   business_facilities: "",
  //   business_social: "",
  //   business_logo: "",

  //   //VIDEO
  //   video: null,
  //   video_name: "",
  //   video_description: "",
  //   video_button: "",
  //   video_url: "",
  //   video_social: {},

  //   //EVENTS
  //   event_action_title: "",
  //   event_action_url: "", //
  //   event_description: "", //
  //   event_title: "", //
  //   event_location_address: "", //
  //   event_location_city: "", //
  //   event_location_country: "", //
  //   event_location_numeration: "", //
  //   event_location_postal_code: "", //
  //   event_location_state: "", //
  //   event_organizer_about: "", //
  //   event_organizer_email: "", //
  //   event_organizer_name: "", //
  //   event_organizer_phone: "", //
  //   event_organizer_website: "", //
  //   event_time_action_title: "",
  //   event_time_all_day: true,
  //   event_time_end: "2024-09-29T19:00:00.000Z",
  //   event_time_start: "2024-09-28T19:00:00.000Z",
  //   event_time_timezone: "",
  //   event_image: "",
  //   event_facilities: "",
  //   event_btn_text: "",

  //   type: "",
  //   style: {
  //     dotsStyle: "", //square
  //     dotsColor: "#000000",
  //     cornerStyle: "",
  //     cornerBackgroundColor: "#ffffff",
  //     cornerBorderColor: "",
  //     cornerDotColor: "",
  //     backgroundColor: "",
  //     frameStyle: "",
  //     frameColor: "", //#404040
  //     frameText: "",
  //     frameTextColor: "",
  //     frameName: "",
  //   },
  //   color: {
  //     background: "",
  //     button: "",
  //   },
  //   social: {},
  // };
  // const [qrData, setQrData] = useState(initialState);

  const qrDataVar = useSelector((state) => state.qrData);
  console.log("qrDataVarr",qrDataVar)
  const [localQrData, setLocalQrData] = useState(qrDataVar);
  console.log("localQrData", localQrData);

  // useEffect(() => {
  //   setQrData((prevState) => ({
  //     ...prevState,
  //     type: type,
  //   }));
  // }, [type]);

  // console.log("qrData", qrData);

  const navigate = useNavigate();

  const handleNextClick = async () => {
    try {
      if (type === "youtube") {
        // await youtubeSchema.validate(qrData);
      }

      // else if (type === "url") {
      //   await UrlSchema.validate(qrData);
      // }
      else if (type === "wifi") {
        // await WifiSchema.validate(qrData);
      } else if (type === "video") {
        // await videoSchema.validate(qrData);
      } else if (type === "image_gallery") {
        // if (!qrData?.gallery_image) {
          // toast.error("Please Upload Image");
          // return;
        // }
      }
      // else if (type === "vcard") {
      //   if (!qrData?.vcard_image) {
      //     toast.error("Please Upload Image");
      //     return;
      //   }
      // }
      else if (type === "business_page") {
        // if (!qrData?.business_logo) {
          // toast.error("Please Upload Logo");
          // return;
        // }
      } else if (type === "events") {
        // if (!qrData?.event_image) {
        // toast.error("Please Upload Cover Image");
        // return;
        // }
      }

      const dataToSend = {
        type: type,
        style: localQrData?.style,
        editID: localQrData?.id,
        qrDesignLogo: localQrData?.qrDesignLogo,
        ...(type === "url"
          ? { field_url: localQrData?.field_url, qr_name: localQrData?.qr_name }
          : {}),
        ...(type === "wifi"
          ? {
              qr_name: localQrData.qr_name,
              network_name: localQrData.network_name,
              network_password: localQrData.network_password,
              network_security_type: localQrData.network_security_type,
            }
          : {}),
        ...(type === "youtube"
          ? {
              qr_name: localQrData.qr_name,
              youtube_url: localQrData.youtube_url,
            }
          : {}),
        ...(type === "pdf"
          ? {
              qr_name: localQrData?.qr_name,
              pdf_file: localQrData?.pdf_file,
              pdf_company: localQrData?.pdf_company,
              pdf_title: localQrData?.pdf_title,
              pdf_description: localQrData?.pdf_description,
              pdf_website: localQrData?.pdf_website,
              color: localQrData?.color,
            }
          : {}),
        ...(type === "apps"
          ? {
              qr_name: localQrData?.qr_name,
              app_name: localQrData?.app_name,
              app_company: localQrData?.app_company,
              app_description: localQrData?.app_description,
              app_website: localQrData?.app_website,
              app_social: localQrData?.app_social,
              color: localQrData.color,
            }
          : {}),
        ...(type === "landing"
          ? {
              qr_name: localQrData?.qr_name,
              landing_action_url: localQrData?.landing_action_url,
              landing_company: localQrData?.landing_company,
              landing_logo: localQrData?.landing_logo,
              landing_title: localQrData?.landing_title,
              landing_subtitle: localQrData?.landing_subtitle,
              landing_btn_text: localQrData?.landing_btn_text,
              landing_social: localQrData?.landing_social,
              color: localQrData.color,
            }
          : {}),
        ...(type === "social_media"
          ? {
              qr_name: localQrData?.qr_name,
              media_headline: localQrData.media_headline,
              color: localQrData.color,
              media_social: localQrData.media_social,
              social_logo: localQrData.social_logo,
              media_description: localQrData.media_description,
            }
          : {}),
        ...(type === "image_gallery"
          ? {
              qr_name: localQrData?.qr_name,
              color: localQrData.color,
              gallery_image: localQrData.gallery_image,
              gallery_title: localQrData.gallery_title,
              gallery_btn_text: localQrData.gallery_btn_text,
              gallery_description: localQrData.gallery_description,
              gallery_website: localQrData.gallery_website,
              gallery_url: localQrData.gallery_url,
            }
          : {}),
        ...(type === "links"
          ? {
              qr_name: localQrData?.qr_name,
              color: localQrData.color,
              links_image: localQrData.links_image,
              links_title: localQrData.links_title,
              links_description: localQrData.links_description,
              all_links: localQrData.all_links,
              links_social: localQrData.links_social,
            }
          : {}),
        ...(type === "vcard"
          ? {
              qr_name: localQrData?.qr_name,
              color: localQrData.color,
              vcard_address: localQrData?.vcard_address || "",
              vcard_city: localQrData?.vcard_city || "",
              vcard_company_name: localQrData?.vcard_company_name || "",
              vcard_country: localQrData?.vcard_country || "",
              vcard_email: localQrData?.vcard_email || "",
              vcard_fax: localQrData?.vcard_fax || "",
              vcard_full_name: localQrData?.vcard_full_name || "",
              vcard_landline_phone: localQrData?.vcard_landline_phone || "",
              vcard_mobile_phone: localQrData?.vcard_mobile_phone || "",
              vcard_numeration: localQrData?.vcard_numeration || "",
              vcard_profession: localQrData?.vcard_profession || "",
              vcard_state: localQrData?.vcard_state || "",
              vcard_summary: localQrData?.vcard_summary || "",
              vcard_website: localQrData?.vcard_website || "",
              vcard_zip_code: localQrData?.vcard_zip_code || "",
              vcard_social: localQrData?.vcard_social || "",
              vcard_image: localQrData?.vcard_image || "",
            }
          : {}),
        ...(type === "business_page"
          ? {
              qr_name: localQrData?.qr_name,
              color: localQrData?.color,
              business_email: localQrData?.business_email,
              business_name: localQrData?.business_name,
              business_phone: localQrData?.business_phone,
              business_website: localQrData?.business_website,
              business_action_title: localQrData?.business_action_title,
              business_url: localQrData?.business_url,
              business_company: localQrData?.business_company,
              business_subtitle: localQrData?.business_subtitle,
              business_title: localQrData?.business_title,
              business_address: localQrData?.business_address,
              business_btn_text: localQrData?.business_btn_text,
              business_city: localQrData?.business_city,
              business_country: localQrData?.business_country,
              business_numeration: localQrData?.business_numeration,
              business_postalcode: localQrData?.business_postalcode,
              business_state: localQrData?.business_state,
              business_about: localQrData?.business_about,
              business_facilities: localQrData?.business_facilities,
              business_social: localQrData?.business_social,
              business_logo: localQrData?.business_logo,
              opening_hours_days: localQrData?.opening_hours_days,
              opening_hours_format: localQrData?.opening_hours_format,
            }
          : {}),
        ...(type === "video"
          ? {
              qr_name: localQrData?.qr_name,
              color: localQrData.color,
              video_social: localQrData.video_social,
              video: localQrData?.video_path,
              video_title: localQrData.video_title,
              video_description: localQrData.video_description,
              video_button: localQrData?.video_button,
              video_url: localQrData.video_url,
              video_name: localQrData.video_name,
            }
          : {}),
        ...(type === "events"
          ? {
              qr_name: localQrData?.qr_name,
              color: localQrData.color,
              event_action_title: localQrData?.event_action_title || "",
              event_action_url: localQrData?.event_action_url || "",
              event_description: localQrData?.event_description || "",
              event_title: localQrData?.event_title || "",
              event_location_address: localQrData?.event_location_address || "",
              event_location_city: localQrData?.event_location_city || "",
              event_location_country: localQrData?.event_location_country || "",
              event_location_numeration:
                localQrData?.event_location_numeration || "",
              event_location_postal_code:
                localQrData?.event_location_postal_code || "",
              event_location_state: localQrData?.event_location_state || "",
              event_organizer_about: localQrData?.event_organizer_about || "",
              event_organizer_email: localQrData?.event_organizer_email || "",
              event_organizer_name: localQrData?.event_organizer_name || "",
              event_organizer_phone: localQrData?.event_organizer_phone || "",
              event_organizer_website:
                localQrData?.event_organizer_website || "",
              event_time_action_title:
                localQrData?.event_time_action_title || "",
              event_time_all_day: localQrData?.event_time_all_day || true,
              event_time_start:
                localQrData?.event_time_start || "2024-09-28T19:00:00.000Z",
              event_time_end:
                localQrData?.event_time_end || "2024-09-29T19:00:00.000Z",
              event_time_timezone: localQrData?.event_time_timezone || "",
              event_image: localQrData?.event_image || "",
              event_facilities: localQrData?.event_facilities || "",
              event_btn_text: localQrData?.event_btn_text || "",
            }
          : {}),
      };
      // sessionStorage.setItem("qrData", JSON.stringify(qrData));
      dispatch(setsQrData(localQrData));
      navigate(`/qr-editor/${type}/design`, { state: { qrData: dataToSend } });
    } catch (error) {
      console.log("error", error);
      toast.error(error.errors[0]);
    }
  };

  const handleCancelClick = () => {
    dispatch(resetQrData());
    navigate(`/qr-editor`);
  };

  const renderDetailContent = () => {
    switch (type) {
      case "url":
        return (
          <>
            <URL localQrData={localQrData} setLocalQrData={setLocalQrData} />
          </>
        );
      case "vcard":
        return (
          <VCARD localQrData={localQrData} setLocalQrData={setLocalQrData} />
        );
      case "pdf":
        return (
          <div>
            <PDF localQrData={localQrData} setLocalQrData={setLocalQrData} />
          </div>
        );
      case "wifi":
        return (
          <div>
            <WIFI localQrData={localQrData} setLocalQrData={setLocalQrData} />
          </div>
        );
      case "youtube":
        return (
          <div>
            <YOUTUBE
              localQrData={localQrData}
              setLocalQrData={setLocalQrData}
            />
          </div>
        );
      case "apps":
        return (
          <div>
            <APPS localQrData={localQrData} setLocalQrData={setLocalQrData} />
          </div>
        );
      case "image_gallery":
        return (
          <div>
            <GALLERY
              localQrData={localQrData}
              setLocalQrData={setLocalQrData}
            />
          </div>
        );
      case "links":
        return (
          <div>
            <LINKS localQrData={localQrData} setLocalQrData={setLocalQrData} />
          </div>
        );
      case "landing":
        return (
          <div>
            <LANDING
              localQrData={localQrData}
              setLocalQrData={setLocalQrData}
            />
          </div>
        );
      case "social_media":
        return (
          <div>
            <Social localQrData={localQrData} setLocalQrData={setLocalQrData} />
          </div>
        );
      case "video":
        return (
          <div>
            <Video localQrData={localQrData} setLocalQrData={setLocalQrData} />
          </div>
        );
      case "business_page":
        return (
          <div>
            <BUSINESS
              localQrData={localQrData}
              setLocalQrData={setLocalQrData}
            />
          </div>
        );
      case "events":
        return (
          <div>
            <EVENT localQrData={localQrData} setLocalQrData={setLocalQrData} />
          </div>
        );
      default:
        return <div>No Form Available</div>;
    }
  };

  return (
    <>
      <Header />
      <div className="qr-editor-detail">
        <div className="top">
          <h1>2. Your QR code content</h1>
        </div>
        {renderDetailContent()}
      </div>

      <BottomWrapperStages
        currentStage={2}
        onNextClick={handleNextClick}
        onCancelClick={handleCancelClick}
        showNextButton={true}
        // qrData={qrData}
      />
    </>
  );
};

export default QRDetail;

// let isValid = true;
// let errorMessage = "";

// if (type === "url") {
//   if (!qrData.field_url) {
//     isValid = false;
//     errorMessage = "Please enter the URL";
//   }
// }
// if (!isValid) {

//   toast.error(errorMessage);
//   return;
// }
